import React from 'react'
import styled, { css } from 'styled-components'

import { breakpoint } from '../../config'

import { flexStyles, flexItemStyles, flexOffset, flexWidth } from '../flex'
import { Box } from '../box'
import { Title } from '../title'
import { Intro } from '../intro'
import { List, ListItem } from '../list'
import { Link } from '../link'
import { Container } from '../container'

import iconImg from '../../assets/truck.svg'
import lokalImg from '../../assets/lokal.svg'
import fairImg from '../../assets/fair.svg'

const Content = styled.div`
  ${flexStyles}
`

const Hero = styled.div`
  ${flexItemStyles}

  @media ${breakpoint.tablet} {
    ${flexWidth(12)}
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(8)}
    ${flexOffset(2)}
  }
`

const mainMetaStyles = css`
  ${flexItemStyles}

  @media ${breakpoint.tablet} {
    ${flexWidth(6)}
  }

  @media ${breakpoint.desktop} {
    ${flexWidth(4)}
  }
`

const Main = styled.div`
  ${mainMetaStyles}

  margin-bottom: 40px;

  @media ${breakpoint.tablet} {
    margin-bottom: 0;
  }

  @media ${breakpoint.desktop} {
    ${flexOffset(2)}
  }
`

const Meta = styled.div`
  ${mainMetaStyles}
`

const StyledBox = styled(Box)`
  padding-bottom: 28px;

  @media ${breakpoint.tablet} {
    padding-bottom: 48px;
  }
`

export function Criteria() {
  return (
    <Container>
      <Content>
        <Hero>
          <Intro icon={iconImg}>Was ist Lokal+Fair?</Intro>
        </Hero>
      </Content>

      <Content>
        <Main>
          <p>
            <strong>
              Lokal+Fair ist eine Initiative der Gemeinde Vaduz, der ersten Fair
              Trade Town in Liechtenstein. Die Auszeichnung Fair Trade Town wird
              von Swiss Fair Trade an Gemeinden und Städte verliehen, welche
              sich für den fairen Handel engagieren.
            </strong>
          </p>
          <p>
            «Fairer Handel» basiert auf Transparenz, Respekt, Gerechtigkeit und
            einem offenen Austausch. Nebst der Zahlung von fairen Preisen, wird
            stark auf die Einhaltung von Umwelt- und Sozialstandards geachtet.
            Produzenten in wirtschaftlich benachteiligten Regionen werden
            gezielt unterstützt und gefördert – angestrebt werden
            partnerschaftliche, langfristige Beziehungen.
          </p>
          <p>
            Wer fair einkaufen oder konsumieren möchte, kann auf das Label{' '}
            <strong>Lokal+Fair</strong> achten.
          </p>
          <p>
            Eine aktuelle Übersicht über Betriebe mit dem{' '}
            <strong>Lokal+Fair</strong> Label wird hier laufend aktualisiert.
          </p>
          <p>
            <Link to="/akteure" arrow primary>
              Akteure
            </Link>
          </p>
        </Main>
        <Meta>
          <StyledBox>
            <Title size={2} heading="h2" margin>
              Wie wird ein Betrieb ausgezeichnet?
            </Title>
            <p>
              Das Label wird von der Fair Trade Town Vaduz an Vaduzer Gewerbe-
              und Gastronomiebetriebe vergeben, welche folgende Kriterien
              erfüllen sowie nachhaltig handeln und beim Einkauf regionale
              Produkte bevorzugen.
            </p>
            <br />
            <List>
              <ListItem icon={lokalImg}>
                Mindestens 1 Produkt aus Liechtenstein.
              </ListItem>
              <ListItem icon={fairImg}>
                Mindestens 3 Fair Trade Produkte.
              </ListItem>
            </List>
          </StyledBox>
        </Meta>
      </Content>
    </Container>
  )
}
