import React from 'react'
import styled from 'styled-components'

import { breakpoint } from '../config'

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItemWrapper = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;

    @media ${breakpoint.tablet} {
      margin-bottom: 28px;
    }
  }
`

const IconImg = styled.img`
  display: block;
  width: 68px;
  height: auto;
  margin-right: 20px;
`

export function List({ children, ...props }) {
  return <ListWrapper {...props}>{children}</ListWrapper>
}

export function ListItem({ children, icon, ...props }) {
  return (
    <ListItemWrapper {...props}>
      {icon && <IconImg src={icon} />}
      <span>{children}</span>
    </ListItemWrapper>
  )
}
