import React from 'react'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Criteria } from '../components/criteria'

export default function CriteriaPage() {
  return (
    <Layout>
      <SEO title="Kriterien" />
      <Criteria />
    </Layout>
  )
}
