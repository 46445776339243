import React from 'react'
import styled from 'styled-components'

import { config } from '../config'

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 20px;
  background: rgba(147, 201, 157, 0.2);
  position: relative;

  @media (min-width: ${config.breakpoints.tablet}) {
    padding: 24px 20px;
  }
`

export function Box({ children, ...props }) {
  return <Wrapper {...props}>{children}</Wrapper>
}
